import React from 'react'
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export default function SliderInnovation() {
    const settings1 = {
        infinite: true,
        speed: 500,
        dots: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 5000,
        arrows: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    };

    return (
        <div>
            <div className='hidden lg:block'>
                <Slider {...settings1}>
                    <div className='SLIDE-1'>
                        <h1 className='tracking-widest text-center text-white text-4xl uppercase font-medium'>Innovation Creators</h1>
                        <div className='w-[40%] mx-auto'>
                            <div><img className='animate-moveSmaller lg:animate-moveLarger' src='/assets/Subtract (1).png'></img></div>
                            <p className='border-b border-white/50'></p>
                        </div>
                        <p className='mx-auto text-center mt-10'>Bring your ideas to life with FABC Labs, from tokenization to <br /> global market launch.</p>

                        <img src='/assets/Fabccore.png' className='mx-auto w-[85%] duration-500 '></img>

                    </div>
                    <div className='SLIDE-2'>
                        <h1 className='tracking-widest text-center text-white text-4xl uppercase font-medium'>Innovation Investors</h1>
                        <div className='w-[40%] mx-auto'>
                            <div><img className='animate-moveSmaller lg:animate-moveLarger' src='/assets/Subtract (1).png'></img></div>
                            <p className='border-b border-white/50'></p>
                        </div>
                        <p className='mx-auto text-center mt-10'>Invest in cutting-edge, tokenized Deep Tech projects with <br /> high-growth potential.</p>
                        <img src='/assets/Group 1597883300.png' className='mx-auto w-[85%] duration-500 '></img>

                    </div>
                    <div className='SLIDE-3'>
                        <h1 className='tracking-widest text-center text-white text-4xl uppercase font-medium'>Enterprises</h1>
                        <div className='w-[40%] mx-auto'>
                            <div><img className='animate-moveSmaller lg:animate-moveLarger' src='/assets/Subtract (1).png'></img></div>
                            <p className='border-b border-white/50'></p>
                        </div>
                        <p className='mx-auto text-center mt-10'>Access tailored Deep Tech solutions to scale your business  <br />and expand into new markets.</p>
                        <img src='/assets/Group 1597883301.png' className='mx-auto w-[85%] duration-500 '></img>

                    </div>

                </Slider>
            </div>
            <div className='block lg:hidden'>
                <Slider className='' {...settings1}>
                    <div className='px-1'>
                        <div className='SLIDE-1'>
                            <h1 className='tracking-widest text-center text-white text-2xl md:text-4xl uppercase font-medium'>Innovation Creators</h1>
                            <div className='w-[90%] md:w-[40%] mx-auto'>
                                <div><img className='animate-moveSmaller lg:animate-moveLarger' src='/assets/Subtract (1).png'></img></div>
                                <p className='border-b border-white/50'></p>
                            </div>
                            <p className='mx-auto text-center mt-10'>Bring your ideas to life with FABC Labs, from tokenization to <br /> global market launch.</p>

                            <img src='/assets/Group 1597883333.png' className='mx-auto w-[85%] duration-500 '></img>

                        </div>
                    </div>
                    <div className='px-1'>
                        <div className='SLIDE-2'>
                            <h1 className='tracking-widest text-center text-white text-2xl md:text-4xl uppercase font-medium'>Innovation Investors</h1>
                            <div className='w-[90%] md:w-[40%] mx-auto'>
                                <div><img className='animate-moveSmaller lg:animate-moveLarger' src='/assets/Subtract (1).png'></img></div>
                                <p className='border-b border-white/50'></p>
                            </div>
                            <p className='mx-auto text-center mt-10'>Invest in cutting-edge, tokenized Deep Tech projects with <br /> high-growth potential.</p>
                            <div className='flex justify-center'>
                                <img src='/assets/Group 1597883331.png' className='mx-auto lg:w-[85%] duration-500 '></img>
                            </div>
                        </div>
                    </div>
                    <div className='px-1'>
                        <div className='SLIDE-3'>
                            <h1 className='tracking-widest text-center text-white text-2xl md:text-4xl uppercase font-medium'>Enterprises</h1>
                            <div className='md:w-[40%] mx-auto'>
                                <div><img className='animate-moveSmaller lg:animate-moveLarger' src='/assets/Subtract (1).png'></img></div>
                                <p className='border-b border-white/50'></p>
                            </div>
                            <p className='mx-auto text-center mt-10'>Access tailored Deep Tech solutions to scale your business  <br />and expand into new markets.</p>
                            <div className='flex justify-center'>
                                <img src='/assets/Group 1597883332.png' className='mx-auto lg:w-[85%] duration-500 '></img>
                            </div>

                        </div>
                    </div>
                </Slider>
            </div>
             <style>
                {`
                .slick-dots li.slick-active button::before {
                  color: white !important; 
                  font-size: 12px  !important;
                  opacity: 1;
                }
                `}
              </style>

        </div>
    )
}
